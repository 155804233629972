<template>
  <container>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <v-card max-width="mx-auto" class="mx-auto" style="mt-5">
          <v-toolbar color="cyan" dark>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>

            <v-toolbar-title>Messages</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon>
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-toolbar>

          <v-list three-line>
            <template v-for="(item, index) in messages">
              <v-subheader
                v-if="item.header"
                :key="item.header"
                v-text="item.header"
              ></v-subheader>

              <v-divider
                v-else-if="item.divider"
                :key="index"
                :inset="item.inset"
              ></v-divider>

              <v-list-item v-else :key="item._id" @click.stop>
                <v-list-item-avatar>
                  <v-img :src="item.avatar" v-if="item.avatar"></v-img>
                  <v-img
                    src="https://cdn.vuetifyjs.com/images/lists/3.jpg"
                    v-else
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-html="item.body"></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="item.body"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
          <v-spacer />
          <v-form v-model="valid" @submit.prevent="addMessage">
            <v-container>
              <v-row>
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="message.body"
                    :rules="[v => !!v || 'Введите сообщение!']"
                    label="New message"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" align="left" justify="botton">
                  <v-btn color="primary" :disabled="!valid" type="submit"
                    >Add message</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </container>
</template>

<script>
const API_URL = process.env.VUE_APP_BASE_API_URL + "/messages";

export default {
  data: () => ({
    messages: [],
    message: {
      body: ""
    }
  }),
  methods: {
    addMessage() {
      // eslint-disable-next-line no-console
      console.log(this.message);
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(this.message),
        headers: {
          "content-type": "application/json"
        }
      })
        .then(resp => resp.json())
        .then(result => {
          // eslint-disable-next-line no-console
          console.log(result);
          // add new message on a page
          if (result.details == null) {
            this.messages.push(result);
          }
          // clear
          //this.message.body = null;
        });
    }
  },
  mounted() {
    // eslint-disable-next-line no-console
    console.log("loaded messages");
    fetch(API_URL)
      .then(resp => resp.json())
      .then(resp_json => {
        // eslint-disable-next-line no-console
        console.log(resp_json);

        this.messages = resp_json;
      });
  }
};
</script>
